import Button from "primevue/button";
import gql from "graphql-tag";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { role } from "@/service/roles";
import { updateRole } from "@/service/roles";
import { useGlobalStore } from "../stores/global-store";
import { apolloClient, nhost } from "../main";

const routes: RouteRecordRaw[] = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: "notFound" },
  },
  {
    path: "/",
    redirect: { name: "home" },
  },
  {
    path: "/",
    redirect: { name: "home" },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/setup",
        children: [
          {
            name: "setup-choice",
            path: "setup-choice:redirect?",
            component: () => import("@/views/pages/setup/SetupChoice.vue"),
          },
          {
            name: "setup-landing-page",
            path: "landingPage",
            meta: {
              requiresNotReg: true,
            },
            component: () => import("@/views/pages/setup/LandingPage.vue"),
          },
        ],
      },
      {
        path: "/",
        children: [
          {
            path: "History/:table/:id",
            name: "see-history",
            component: () => import("@/views/pages/History.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/",
    children: [
      {
        path: "/",
        meta: {
          requiresAuth: true,
          requiresReg: true,
        },
        children: [
          {
            path: "/home",
            name: "home",
            component: () => import("@/views/Home.vue"),
          },
          {
            path: "/settings",
            children: [
              {
                path: "user",
                children: [
                  {
                    path: "general",
                    name: "general-settings",
                    component: () =>
                      import("@/views/pages/settings/user/EditProfile.vue"),
                  },
                  {
                    path: "ResetPassword",
                    name: "reset-password",
                    component: () =>
                      import("@/views/pages/settings/user/ResetPassword.vue"),
                  },
                ],
              },
              {
                meta: {
                  allowedRoles: ["tenant_admin"],
                },
                path: "modules-config",
                children: [
                  {
                    path: "users",
                    name: "users-config",
                    component: () =>
                      import("@/views/pages/settings/user-modules/User.vue"),
                  },
                  {
                    path: "user-access/:id",
                    name: "user-module-access",
                    component: () =>
                      import("@/views/pages/settings/user-modules/Modules.vue"),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/magic/:ticket/:redirectTo",
    name: "home-magic",
    component: () => import("@/views/MagicRedirect.vue"),
  },
  {
    path: "/errors",
    children: [
      {
        path: "/not-found",
        name: "notFound",
        component: () => import("@/components/errors/NotFound.vue"),
        props: {
          Button,
        },
      },
      {
        path: "/under-construction",
        name: "underConstruction",
        component: () => import("@/components/errors/UnderConstruction.vue"),
        props: {
          Button,
        },
      },
      {
        path: "/access-denied",
        name: "access-denied",
        component: () => import("@/components/errors/AccessDenied.vue"),
        props: {
          Button,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  await nhost.auth.refreshSession()

  const globalStore = useGlobalStore();
  if (from?.fullPath && to.fullPath != from?.fullPath)
    globalStore.hideLoading();

  const requiresAuth = to.meta.requiresAuth;
  const requiresNotAuth = to.meta.requiresNotAuth;
  const requiresReg = to.meta.requiresReg;
  const requiresNotReg = to.meta.requiresNotReg;
  const requiresPickedTenant = to.meta.requiresPickedTenant;
  const requiresOrganizationId = to.meta.requiresOrganizationId;
  const allowedRoles = to.meta.allowedRoles as string[];
  const isAuthenticated = await nhost.auth.isAuthenticatedAsync();
  nhost.auth.refreshSession();
  const isReg =
    nhost.auth.getHasuraClaim("x-hasura-Tenant-User-Id") ||
    nhost.auth.getHasuraClaim("x-hasura-Tenant-ADM-Id");
  const hasPickedTenant = !!nhost.auth.getHasuraClaim("x-hasura-Tenant-Now-Id");

  if (requiresAuth && !isAuthenticated) {
    window.location.href = "auth#/login";
    return;
  }
  const role = await updateRole();
  if (allowedRoles && !allowedRoles.includes(role as string)) {
    next({ name: "access-denied" });
    return;
  }
  if (requiresReg && to.name != "setup-landing-page" && !isReg) {
    next({ name: "setup-landing-page" });
    return;
  }
  if (requiresPickedTenant && !hasPickedTenant) {
    next({ name: "setup-choice" });
    return;
  }
  if ((requiresNotAuth && isAuthenticated) || (requiresNotReg && isReg)) {
    next({ name: "home" });
    return;
  }

  if (to.query.nextTo) {
    next({ name: to.query.nextTo.toString() });
  }

  next();
});

export default router;
